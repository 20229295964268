.SnowBallTable {
  color: #666;
  font: 13px/20px "Open Sans", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", Sans-Serif;
}

/* .table-wrapper {
  overflow-x: auto;
  width: 100%;
  max-width: 100%;
} */

.SnowballTable-Info {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  width: 700px;
  min-width: 700px;
}
.SnowballTable-Percent {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  width: 220px;
  min-width: 220px;
}
.SnowballTable-Index {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  width: 250px;
  min-width: 250px;
}

.table-blank-header {
  background: #fff;
  color: #fff;
  text-align: left;
}
th {
  background: #42444e;
  color: #fff;
  text-align: left;
  position: sticky;
  top: 0;
  z-index: 1;
}

tr:first-child th:first-child {
  border-top-left-radius: 6px;
}
tr:first-child th:last-child {
  border-top-right-radius: 6px;
}
td {
  border-right: 1px solid #c6c9cc;
  border-bottom: 1px solid #c6c9cc;
}
td:first-child {
  border-left: 1px solid #c6c9cc;
}
tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}
tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}

.zero {
  color: gray;
}

.plus {
  color: #FF0F34;
}
.plus:before{
  content: '\25B2 ';
  color: #FF0F34;
}

/* 1 */
.minusClass:before{
  content: '\25BC ';
  color: #0000FF;
}
.minusClass {
  color: #0000FF;
}

.plusClassRoundBracket {
  color: #FF0000;
}

.minusClassRoundBracket {
  color: #0000FF;
}
.minusClassRoundBracket:before {
  content: '\0028';
  color: #0000FF;
}
.minusClassRoundBracket:after {
  content: '\0029';
  color: #0000FF;
}

/* 2 */

.plusClassPer {
  color: #FF4F32;
}
.plusClassPer:before{
  content: '\002B ';
  color: #FF4F32;
}
.plusClassPer:after {
  color: #FF4F32;
  content: '\0025 ';
}


.minusClassPer {
  color: #307AFF;
}
.minusClassPer:after {
  content: '\0025';
  color: #307AFF;
}


.table-box-wrap .table-box table thead tr {
  position: absolute;
  top: 0;
}