.DataTable {
  color: #666;
  font: 14px/26px "Open Sans", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", Sans-Serif;
}

.table-wrapper {
  overflow-x: auto;
  width: 100%;
  max-width: 100%;
}

.my-data-table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  min-width: 600px;
  width: auto;
}
th,
td {
  padding: 3px 3px;
}

.table-blank-header {
  background: #fff;
  color: #fff;
  text-align: left;
}
th {
  background: #42444e;
  color: #fff;
  text-align: left;
  position: sticky;
  top: 0;
  z-index: 1;
}

tr:first-child th:first-child {
  border-top-left-radius: 6px;
}
tr:first-child th:last-child {
  border-top-right-radius: 6px;
}
td {
  border-right: 1px solid #c6c9cc;
  border-bottom: 1px solid #c6c9cc;
}
td:first-child {
  border-left: 1px solid #c6c9cc;
}
.my-data-table tr:nth-child(even) td {
  background: #eaeaed;
}
tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}
tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}

.zero {
  color: gray;
}

.plus {
  color: #FF0F34;
}
.plus:before{
  content: '\25B2 ';
  color: #FF0F34;
}

.minusClass {
  color: #0000FF;
}
.minusClass:before{
  content: '\25BC ';
  color: #0000FF;
}

/* 그리드 추가 버튼 좌우 padding */
/*
.ui.grid.narrow >.column:not(.row) {
  padding-left: 1.7rem;
  padding-right: 1.7rem;
}
*/

.table-box-wrap .table-box table thead tr {
  position: absolute;
  top: 0;
}