.common-table {
    width: 80%;
    margin: 0 auto;
    text-align: center;
    border-spacing: 0;
  }
  
  .common-table-header-column {
    border-bottom: 1px solid #999999;
    padding: 0;
    font-size: 16px;
    padding: 10px 5px;
    font-weight: bold;
  }
  
  .common-table-row:hover {
    background-color: #eceaea;
    cursor: pointer;
  }
  
  .common-table-column {
    padding: 10px 5px;
  }