.post-view-wrapper {
  width: 60%;
  margin: 0 auto;
}

.post-view-go-list-btn {
  border: 0;
  padding: 10px;
  background-color: #ffd9d9;
}

.post-view-row {
  margin: 10px 0;
  display: flex;
}

/* .post-view-row > label:first-child {
  margin: 10px 0;
  width: 30%;
  font-weight: bold;
} */

.post-view-row > *:nth-child(2) {
  /* margin: 10px 0; */
  /* width: 70%; */
}

.post-view-top {
  background: #dcdcdc;
  border-radius: 8px;
  padding: 10px;
  height: auto;
  min-height: 30px;
  overflow: auto;
}

.left-box {
  
}
.right-box {
  float: right;
}

.post-view-title {
  padding: 10px;
}

.textbox {
  position: relative;
}
.textbox label {
  position: absolute;
  top: 1px; /* input 요소의 border-top 설정값 만큼 */
  left: 1px; /* input 요소의 border-left 설정값 만큼 */
  padding: 0.8em 0.5em; /* input 요소의 padding 값 만큼 */
  color: #999;
  cursor: text;
}
.textbox input[type="text"],
.textbox input[type="password"] {
  width: 100%; /* 원하는 너비 설정 */
  height: auto; /* 높이값 초기화 */
  line-height: normal; /* line-height 초기화 */
  padding: 0.8em 0.5em; /* 원하는 여백 설정, 상하단 여백으로 높이를 조절 */
  font-family: inherit; /* 폰트 상속 */
  border: 1px solid #999;
  border-radius: 0; /* iSO 둥근모서리 제거 */
  outline-style: none; /* 포커스시 발생하는 효과 제거를 원한다면 */
  -webkit-appearance: none; /* 브라우저별 기본 스타일링 제거 */
  -moz-appearance: none;
  appearance: none;
}
